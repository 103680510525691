import React, { useState, useEffect, createRef } from "react";
import { graphql } from 'gatsby';
import styled from "styled-components";
import { gsap } from "gsap";
import Arrow from "../assets/svg/arroworange.inline.svg";
import ArrowReload from "../assets/svg/arrowreload.inline.svg";
import Close from "../assets/svg/close.inline.svg";
import Listen from "../assets/svg/listen.inline.svg";
import BigMuscle from "../assets/svg/musclebig.inline.svg";
import { Flex, Box } from "@chakra-ui/react";
import SEO from "../components/seo";
import Nav from "../components/Nav";

const Container = styled.div`
	display: flex;
	padding-top: 60px;
`;

const Left = styled.div`
	width: 45%;
`;

const Right = styled.div`
	position: relative;
	width: 55%;
	max-width: 600px;
	min-height: 800px;
`;

const Title = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 3rem;
	line-height: 0.94;
	text-align: left;
	color: #1a1818;
	text-transform: lowercase;
	padding-bottom: 2rem;
	hyphens: auto;
`;

const SubTitle = styled.h3`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.04;
	color: #1a1818;
	padding-top: 2.5rem;
	padding-bottom: 1.5rem;
`;

const ParaGraph = styled.div`
	p {
		font-family: "Poppins";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
	}
`;

const ListContainer = styled.div``;

const ListItem = styled.div`
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	cursor: pointer;
`;

const ListItemText = styled.span`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.04;
	color: #1a1818;
	padding-left: 1rem;
`;

const Content = styled.div``;
const Modal = styled.div`
	position: absolute;
	top: 100px;
	width: 100%;
	visibility: hidden;
`;

const ModalActionWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const ModalTitle = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 2.25rem;
	line-height: 1.04;
	text-align: left;
	color: #1a1818;
	padding-bottom: 2rem;
	@media (min-width: 80em) {
	font-size: 2.85rem;
	}
`;

const Text = styled.span`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.04;
	color: #1a1818;
	padding-left: 10px;
`;

export default function RohkaisuPage({ data: { page } }) {
	const [sentences, setSentences] = useState(
		page.rohkaisuData.rohkaisutRepeater
	);
	const [currentSentence, setCurrentSentence] = useState(null);

	const content = createRef();
	const modal = createRef();

	const sounds = createRef({ current: null });

	const getRandomItem = () =>
		sentences[Math.floor(Math.random() * sentences.length)];

	const doRandoming = () => {
		const random = getRandomItem();
		setCurrentSentence(random);
		sounds.current.src = random.rohkaisulauseSound.mediaItemUrl;
	};

	const openModal = () => {
		doRandoming();
		gsap.to(content.current, { duration: 0.5, autoAlpha: 0 });
		gsap.to(modal.current, { duration: 0.5, autoAlpha: 1 });
	};

	const closeModal = () => {
		gsap.to(content.current, { duration: 0.5, autoAlpha: 1 });
		gsap.to(modal.current, { duration: 0.5, autoAlpha: 0 });
	};

	const click = () => {
		openModal();
	};

	return (
		<>
			<Nav />
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Box pos="relative" width="100%" maxW="1200px" mx="auto" px="16px">
				<Box
					pos="absolute"
					top="-40px"
					left={{ md: "-380px", lg: "-300px"}}
					display={["none", "none", "block"]}
					w={800}
				>
					<BigMuscle />
				</Box>
				<Flex pt={["40px", "60px"]}>
					<Box pr={[0, 0, "40px"]} w={[0, 0, "45%"]}></Box>
					<Box
						position="relative"
						pl={[0, 0, "40px"]}
						w={["100%", "100%", "55%"]}
						maxW="600px"
						mx={["auto"]}
						minHeight={["auto", 800]}
					>
						<Content ref={content}>
							<Title>{page.frontpageLinkTitle.styledTitle.replace(/-/g, '\u00AD')}</Title>
							<ParaGraph
								dangerouslySetInnerHTML={{
									__html: page.content,
								}}
							/>

							<SubTitle>
								{page.rohkaisuData.rohkaisuHeadingRepeater}
							</SubTitle>
							{
								<ListContainer>
									{page.rohkaisuData.rohkaisuRepeater.map(
										(item, i) => (
											<ListItem
												key={`rohkaisu-${i}`}
												onClick={click}
											>
												<Arrow />
												<ListItemText>
													{item.rohkaisuTeko}
												</ListItemText>
											</ListItem>
										)
									)}
								</ListContainer>
							}
						</Content>
						<Flex
							direction="column"
							justifyContent="flex-end"
							pos="absolute"
							top={["20px","20px","80px"]}
							width="100%"
							height="300px"
							visibility="hidden"
							ref={modal}
						>
							<ModalTitle>
								{currentSentence?.rohkaisulauseText}
							</ModalTitle>
							<Flex justifyContent="space-between" w={["100%","80%","80%"]}>
								<ModalActionWrapper
									onClick={() => sounds.current.play()}
								>
									<Box w={["50px","50px","60px"]}><Listen /></Box>
									<Text>Kuuntele</Text>
								</ModalActionWrapper>
								<ModalActionWrapper
									onClick={() => doRandoming()}
								>
									<Box w={["50px","50px","60px"]}><ArrowReload /></Box>
									<Text>Uusi</Text>
								</ModalActionWrapper>
								<ModalActionWrapper onClick={closeModal}>
									<Box w={["50px"]}><Close /></Box>
								</ModalActionWrapper>
							</Flex>
						</Flex>
					</Box>
				</Flex>
				<audio ref={sounds} />
			</Box>
		</>
	);
}

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "rohkaisulausepankki" }) {
			slug
			title
			seo {
				metaDesc
				title
			}
			rohkaisuData {
				rohkaisuRepeater {
					rohkaisuTeko
				}
				rohkaisutRepeater {
					rohkaisulauseText
					rohkaisulauseSound {
						mediaItemUrl
					}
				}
				rohkaisuHeadingRepeater
			}
			content
			frontpageLinkTitle {
				styledTitle
			}
		}
	}
`;
